.header {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
  background: #ffffff;
  box-shadow: 0px 4px 4px 0px #0000000d;
}

.header__logo {
  max-width: 415px;
  width: 100%;
  margin: 0 auto;
}
